* {
  box-sizing: border-box;
}

.mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right {
  display: none !important;
}

html {
  background-image: linear-gradient(-180deg, #110F12 7%, #0C0C0C 99%);
  color: #fff;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: initial !important;
}

body[data-cursor="pointer"] {
  cursor: pointer !important;
}

body[data-cursor="grab"] {
  cursor: grab !important;
}
body[data-cursor="grabbing"] {
  cursor: grabbing !important;
}

a:hover, a:visited, a:link, a:active {
  color: currentColor;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
  line-height: 1;
  font-weight: 300;
}

h3 {
  font-size: 2.5rem;
}

h4 {
  font-size: 2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rc-tooltip {
  pointer-events: none;
}

.potree_menu_toggle {
  display: none;
}

/* 
  avoid outline borders around elements that are only focusable for hotkeys purposes
*/
/* div[tabindex="-1"]:focus {
    outline: 0;
} */

#profileInfo, 
#profile_titlebar, 
#closeProfileContainer, 
#profile_window_title, 
#profile_window {
  background-color: #1e1e1e;
  color: white;
  text-shadow: none;
}


#closeProfileContainer i{
  font-size: 16px;
}

#profile_window_title {
  position: relative;
  margin: 0;
  padding-left: 1em;
}

#profile_titlebar {
  font-weight: normal;
  display: flex;
  align-items: center;
}

.button-icon:hover {
  filter: none;
  background-color: transparent;
  cursor: pointer;
}

#potree_download_profile_ortho_link, 
#potree_download_profile_link {
  font-size: 12px;
  margin-left: .75rem;
}

.thumb-vertical {
  border-radius: 100px;
  background-color: #aaa;
}